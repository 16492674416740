import React from 'react'

const About = () => {
  return (
   <section>
        <h1>About Page</h1>
   </section>
  )
}

export default About